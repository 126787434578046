import React from 'react'
import Layout from '../../components/layout';
import Helmet from "react-helmet"
import SearchComponent from "../../components/search/searchperks"

const Home = () => {
    return (
        <Layout includeSearchBar={false}>
            <Helmet>
                <title>Vantage Rewards </title>

                <meta name="description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                <meta property="twitter:title" content="Vantage Circle" />
                        
                <meta property="twitter:description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://docs.vantagecircle.com/vantage-perks/employeebenefits-fundamentals/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <meta property="og:title" content="Vantage Circle" />

                <meta property="og:description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                <link rel="canonical" href="https://docs.vantagecircle.com/vantage-perks/employeebenefits-fundamentals/"></link>
            </Helmet>
            <SearchComponent />
            <section className="container max-w-6xl mx-auto py-10 md:pt-20 md:pb-0">
                <div className="mx-auto px-6 mb-10">
                    <div className="text-center">
                        <h2 className="section-title">Employee Perks & Benefits <span className="text-orange">Fundamentals</span></h2>
                    </div>
                </div>
            </section>

            <section className="container max-w-4xl vc-doc-content px-6 md:px-12 pb-10">
                <h2>What is meant by employee perks and benefits?</h2>
                <p>Employee perks and benefits are the indirect and non-cash compensation paid to an employee over their salaries to create a competitive package for them. They are also called fringe benefits meant to attract and retain employees.</p>
                <p>Advantages of a competitive employee benefits package:</p>
                <ul className="list-inside orangebullets">
                    <li>Increased levels of productivity and <a href="https://www.vantagecircle.com/en/blog/employee-engagement/">employee engagement</a></li>
                    <li>More <a href="https://www.vantagecircle.com/en/blog/job-satisfaction/">satisfied employees</a> indicate more effectiveness in their jobs.</li>
                    <li>Higher <a href="https://www.vantagecircle.com/en/blog/employee-retention-strategies/">employee retention.</a></li>
                    <li>Boost in <a href="https://www.vantagecircle.com/en/blog/improve-employee-morale-in-workplace/">employee morale.</a></li>
                    <li>Higher levels of dedication and <a href="https://www.vantagecircle.com/en/blog/employee-loyalty/">loyalty towards the organization.</a></li>
                </ul>
                <h2>Types of employee perks and benefits</h2>
                <p><a href="https://www.vantagecircle.com/en/blog/employee-benefits-compensation-ideas/">Employee perks</a> have been traditionally limited to insurance plans, disability allowances, tuition reimbursements, etc. But as the modern workforce becomes more diverse, employee benefits programs need to cater to all the employees' needs.</p>
                <p>Today’s talent is looking for more than just a big paycheck—they want to work at companies that enable a certain kind of lifestyle.</p>
                <p>In fact, a <a href="https://www.glassdoor.com/blog/ecs-q3-2015/">study by Glassdoor</a> has found that 80% of employees wish for perks or benefits more than they want a pay raise. That’s why employee perks like corporate discounts, personalized experiences, <a href="https://www.vantagecircle.com/en/blog/employee-rewards-and-recognition/">rewards and recognition</a>, and wellness programs matter to employees.</p>
                <h3>Exclusive Corporate Offers</h3>
                <p>Special corporate discounts on top brands make a fantastic employee perk. Users get discounts on everything—from dining to fitness to retail. Working with a third party eliminates the hassle of negotiating discounts yourself. At the end of the day, employers want their employees to know they care about their well-being, and exclusive employee discounts are a great way to show that.</p>
                <p>Vantage Perks is an exclusive platform that houses the best offers and deals from the top brands and retailers from across the globe. These deals are verified and validated by our team regularly. Simply roll out our platform to your employees and rest easy. We also have a special team that helps the users with any support-related queries.</p>
                <h3>Reward Points</h3>
                <p>With our loyalty reward solution, we offer users the option of using reward points to shop across their favorite online shopping sites. Rewards points are tied up to a specific monetary value. Employees can use these points for buying merchandise, experiences, and <a href="https://www.vantagecircle.com/en/blog/gift-cards-employee-reward/">gift vouchers</a>.</p>
                <p>Reward points are earned by users in two ways, either as a part of an organization’s employee recognition program or in the form of cashback.</p>
                <h3>Cashback</h3>
                <p>Cashback is another attractive option you can give your employees as a perk. It works on a similar principle as corporate discounts, but instead of getting money off, like in discounts, employees get reward points back when they buy goods from retailers.</p>
                <p>Vantage Perks, our employee benefits solution, offers cashback from popular e-commerce players and retailers over and above any discounts already offered by the retailer site. Such cashback is given in the form of reward points that are usually dispersed within 72 hours of placing an order successfully.</p>
            </section>
        </Layout>
    )
}

export default Home